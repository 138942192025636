import React from 'react'
import './footer.css'
import {RiInstagramFill} from 'react-icons/ri'
import {BsFacebook} from 'react-icons/bs'
import {MdEmail} from 'react-icons/md'

const Footer = () => {
  return (
    <footer>
      <a href="" className='footer__logo'>H. ALANLI</a>
  
    <ul className='permalinks'>
      <li>
        <a href="#">Home</a>
      </li>
      <li>
        <a href="#about">About</a>
      </li>
      <li>
        <a href="#experience">Experience</a>
      </li>
      <li>
        <a href="#workExperience">Work Experience</a>
      </li>
      <li>
        <a href="#references">References</a>
      </li>
      <li>
        <a href="#contact">Contact</a>
      </li>
    </ul>
    <div className="footer__socials">
      <a target='_blank' href="https://facebook.com/alanli93"><BsFacebook /></a>
      <a target='_blank' href="https://www.instagram.com/alanli/"><RiInstagramFill /></a>
      <a target='_blank' href="mailto:huseyin_alanli@yahoo.com"><MdEmail /></a>
    </div>

    <div className="footer__copyright">
      <small>&copy; H. ALANLI 2023. All rights reserved.</small>
    </div>
    </footer>
  )
}

export default Footer