import React from 'react'
import './nav.css'
import {AiOutlineHome} from 'react-icons/ai'
import {AiOutlineUser} from 'react-icons/ai'
import {GoBook} from 'react-icons/go'
import {MdOutlineHomeWork} from 'react-icons/md'
import {AiOutlineMessage} from 'react-icons/ai'
import {MdWorkOutline} from 'react-icons/md'
import {useState} from 'react'

const Nav = () => {
  const [activeNav, setActiveNav] = useState('#')
  return (
   <nav>
   <a href="#"  onClick={() => setActiveNav('#')} className={activeNav == '#' ? 'active' : ''}><AiOutlineHome /></a>
   <a href="#about" onClick={() => setActiveNav('#about')} className={activeNav === '#about' ? 'active' : ''}><AiOutlineUser /></a>
   <a href="#experience" onClick={() => setActiveNav('#experience')} className={activeNav === '#experience' ? 'active' : ''}><GoBook /></a>
   <a href="#workExperience" onClick={() => setActiveNav('#workExperience')} className={activeNav === '#workExperience' ? 'active' : ''}><MdOutlineHomeWork /></a>
   <a href="#portfolio" onClick={() => setActiveNav('#portfolio')} className={activeNav === '#portfolio' ? 'active' : ''}><MdWorkOutline /></a>
   <a href="#contact" onClick={() => setActiveNav('#contact')} className={activeNav === '#contact' ? 'active' : ''}><AiOutlineMessage /></a>
   </nav>
  )
}

export default Nav