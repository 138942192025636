import React from 'react'
import {BsLinkedin} from 'react-icons/bs'
import {RiInstagramFill} from 'react-icons/ri'
import {BsFacebook} from 'react-icons/bs'

const HeaderSocials = () => {
  return (
   <div className="header__socials">
    <a href='https://uk.linkedin.com/in/huseyin-alanli-7031b5b2' target='_blank'><BsLinkedin /></a>
    <a href='https://www.instagram.com/alanli/' target='_blank'><RiInstagramFill /></a>
    <a href='https://facebook.com/alanli93' target='_blank'><BsFacebook /></a>
   </div>
  )
}

export default HeaderSocials