import React from 'react'
import './services.css'
import {BsCheckCircleFill} from 'react-icons/bs'

const Services = () => {
  return (
    <section id='workExperience'>
      <h5>Employment</h5>
      <h2>Work Experience</h2>
      <div className="container services__container">
        <article className='service'>
          <div className="service__head">
            <h3>Frontend Developer</h3>
            <h4>@ YOU Agency</h4>
            <h4><small>2022-Current</small></h4>
          </div>
          <ul className='service__list'>
          <li>
            <div className='icon'><BsCheckCircleFill className='service__list-icon' /></div>
              <p>Using HTML5, SCSS/SASS, Vanilla Javascript, Bootstrap, Responsive design implementation, integrating APIs, XHR Requests, Fetch API, Git, PHP, WordPress, Advanced Custom Fields(ACF).</p>
            </li>
            <li>
            <div className='icon'> <BsCheckCircleFill className='service__list-icon' /></div>
              <p>Collaborating with the current development team while also taking ownership of individual tasks</p>
            </li>
            <li>
            <div className='icon'><BsCheckCircleFill className='service__list-icon' /></div>
              <p>Remaining up-to-date with the best practices and standards in web development</p>
            </li>
            <li>
            <div className='icon'> <BsCheckCircleFill className='service__list-icon' /></div>
              <p>Strengthened my technical analysis and problem-solving skills</p>
            </li>
            
        

          </ul>
        </article>
        {/*End of UI/UX*/}
        <article className='service'>
          <div className="service__head">
          <h3>Web Developer & Designer</h3>
            <h4>@ TheYachtMarket.com</h4>
            <h4><small>2018-2022</small></h4>
          </div>
          <ul className='service__list'>
            <li>
             <div className='icon'><BsCheckCircleFill className='service__list-icon' /></div>
              <p>Experienced using HTML, JavaScript, Responsive CSS (Sass) and XML/ JSON. Razor, VB/C#, .NET, MVC, Core and Microsoft SQL Server</p>
            </li>
            <li>
            <div className='icon'><BsCheckCircleFill className='service__list-icon' /></div>
              <p>Experienced on Adobe Creative Cloud: Adobe Illustrator, Adobe Phototshop, Adobe Indesign, Adobe Experience Design and Adobe Premiere Pro</p>
            </li>
            <li>
            <div className='icon'><BsCheckCircleFill className='service__list-icon' /></div>
              <p>Improved my engine optimisation techniques</p>
            </li>
            <li>
            <div className='icon'><BsCheckCircleFill className='service__list-icon' /></div>
              <p>Experienced in developing website in Umbraco CMS, and experience in digital marketing platforms such as Google AdWords and Facebook including re-marketing techniques</p>
            </li>
            <li>
            <div className='icon'><BsCheckCircleFill className='service__list-icon' /></div>
              <p>Used software version control systems such as Git. and experienced with using Jira</p>
            </li>
           

          </ul>
        </article>
        {/*End of Web dev*/}
       
      </div>
    </section>
  )
}

export default Services