import React from 'react'
import './portfolio.css'
import IMG1 from '../../assets/elit-avia.JPG'
import IMG2 from '../../assets/elounda-hills.JPG'
import IMG3 from '../../assets/altrix.JPG'
import IMG4 from '../../assets/aerial-direct.JPG'
import IMG5 from '../../assets/gosh-food.jpg'
import IMG6 from '../../assets/kidadl.JPG'

const data = [
  {
    id: 1,
    image: IMG1,
    title: 'Elit Avia',
    learnmore: '#contact',
    livewebsite: 'https://elitavia.com/'
  },
  {
    id: 2,
    image: IMG2,
    title: 'Elounda Hills',
    learnmore: '#contact',
    livewebsite: 'https://eloundahills.gr/'
  },
  {
    id: 3,
    image: IMG3,
    title: 'Altrix',
    learnmore: '#contact',
    livewebsite: 'https://altrix.co.uk/'
  },
  {
    id: 4,
    image: IMG4,
    title: 'Aerial Direct',
    learnmore: '#contact',
    livewebsite: 'https://www.aerial-direct.co.uk/'
  },
  {
    id: 5,
    image: IMG5,
    title: 'Gosh! Food',
    learnmore: '#contact',
    livewebsite: 'https://www.goshfood.com/'
  },
  {
    id: 6,
    image: IMG6,
    title: 'Kidadl',
    learnmore: '#contact',
    livewebsite: 'https://kidadl.com/'
  }

]

const Portfolio = () => {
  return (
    <section id='portfolio'>
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>
      <div className="container portfolio__container">

{
  data.map(({id, image, title, learnmore, livewebsite}) => {
    return(
      <article key={id} className='portfolio__item'>
      <div className="portfolio__item-image">
        <img src={image} alt={title} />
      </div>
        <h3>{title}</h3>
        <div className="portfolio__item-cta">
        <a href={learnmore} className='btn'>Learn More</a>
        <a href={livewebsite} className='btn btn-primary' target='_blank'>Live Website</a>
        </div>
    </article>

    )
  })
}
      </div>
    </section>
  )
}

export default Portfolio