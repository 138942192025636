import React from 'react'
import './contact.css'
import {MdOutlineEmail} from 'react-icons/md'
import {RiMessengerFill} from 'react-icons/ri'
import {RiInstagramFill} from 'react-icons/ri'
import { useRef } from 'react';
import emailjs from 'emailjs-com';


const Contact = () => {

  const form =  useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_xihbd95', 'template_3hsznw5', form.current, 'vN6SRIE4zfjXiJ2Uw')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset();

  };

  return (
    <section id='contact'>
      <h5>Get In Touch</h5>
      <h2>Contact Me</h2>

      <div className="container contact__container">
        <div className="contact__options">
          <article className='contact__option'>
            <MdOutlineEmail className='contact__option-icon' />
            <h4>Email</h4>
            <h5 className='break'>huseyin_alanli@yahoo.com</h5>
            <a target='_blank' href="mailto:huseyin_alanli@yahoo.com">Send a message</a>
          </article>
          <article className='contact__option'>
            <RiMessengerFill className='contact__option-icon' />
            <h4>Messenger</h4>
            <h5>halanli</h5>
            <a target='_blank' href="https://m.me/halanli93">Send a message</a>
          </article>
          <article className='contact__option'>
            <RiInstagramFill className='contact__option-icon' />
            <h4>Instagram</h4>
            <h5>@alanli</h5>
            <a target='_blank' href="https://www.instagram.com/alanli/">Learn more</a>
          </article>
        </div>
        <form ref={form} onSubmit={sendEmail}>
          <input type="text" name='name' placeholder='Your Full Name' required />
          <input type="email" name='email' placeholder='Your Email' required />
          <textarea name="message" cols="30" rows="7" placeholder='Your Message' required></textarea>
          <button type='submit' className='btn btn-primary'>Send Message</button>
        </form>
      </div>
    </section>
  )
}

export default Contact