import React from 'react'
import './testimonials.css'
import AVTR1 from '../../assets/you.jpg'
import AVTR2 from '../../assets/tym.jpg'
import AVTR3 from '../../assets/you.jpg'
import AVTR4 from '../../assets/tym.jpg'

// import Swiper core and required modules
import { Pagination } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

const data = [
  {
    avatar: AVTR1,
    name: 'You Agency',
    review: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Aliquid, at perspiciatis quibusdam minus quaerat aut molestiae in. Aut aspernatur distinctio natus. Mollitia beatae error earum reiciendis quae. Maiores, deleniti. Mollitia.'
  },
  {
    avatar: AVTR2,
    name: 'TheYachtMarket',
    review: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Aliquid, at perspiciatis quibusdam minus quaerat aut molestiae in. Aut aspernatur distinctio natus. Mollitia beatae error earum reiciendis quae. Maiores, deleniti. Mollitia.'
  },
  {
    avatar: AVTR3,
    name: 'You Agency',
    review: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Aliquid, at perspiciatis quibusdam minus quaerat aut molestiae in. Aut aspernatur distinctio natus. Mollitia beatae error earum reiciendis quae. Maiores, deleniti. Mollitia.'
  },
  {
    avatar: AVTR4,
    name: 'TheYachtMarket',
    review: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Aliquid, at perspiciatis quibusdam minus quaerat aut molestiae in. Aut aspernatur distinctio natus. Mollitia beatae error earum reiciendis quae. Maiores, deleniti. Mollitia.'
  }
]

const Testimonials = () => {
  return (
    <section id='references'>
      <h5>References from Clients</h5>
      <h2>References</h2>

      <Swiper className="container testimonials__container"
       // install Swiper modules
       modules={[ Pagination ]}
       spaceBetween={40}
       slidesPerView={1}
    
       pagination={{ clickable: true }}
      
      >

        {
          data.map(({avatar, name, review}, index) => {
            return(
              <SwiperSlide className='testimonial'>
              <div className="client__avatar">
                <img src={avatar} alt="Avatar One" />
              </div>
              <h5 className='client__name'>{name}</h5>
                <small className='client__review'>
                  {review}
                </small>
            </SwiperSlide>
        
            )
          })
          
        }
      </Swiper>
    </section>
  )
}

export default Testimonials